//
//
//
//
//
//

export default {
  props: {
    blok: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    src() {
      if ((this.$mq == 'sm' || this.$mq == 'lg') && this.blok.mobile) {
        return this.blok.mobile.filename
      } else {
        return this.blok.default.filename
      }
    }
  }
}
